import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    marginTop: '100px',
    height: '100vh',
    width: '100%',
  },
}));

export default useStyles;
