import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from '@/assets/rebranding/Igloo.json';
import useStyles from './style';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CustomSpinner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Lottie
        options={defaultOptions}
        height="100%"
        width="100%"
        style={{ maxWidth: '500px', width: '100%', height: 'auto' }}
      />
    </Box>
  );
};

export default CustomSpinner;
